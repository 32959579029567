<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl">
            <el-button type="primary" @click="getdetail()" size="mini" class="el-icon-plus" style="margin-right: 10px">
              新增供应商
            </el-button>
            <el-radio-group @change="searchInit()" size="mini" v-model="isClose" style="margin-right:10px">
              <el-radio-button label="0">打开</el-radio-button>
              <el-radio-button label="1">关闭</el-radio-button>
            </el-radio-group>
            <el-input v-model="search.supplierName" clearable placeholder="输入供应商名称" size="mini"
              style="width: 120px; margin-right: 10px" @change="searchInit()">
            </el-input>
            <el-input v-model="search.mobile" clearable placeholder="输入手机号" size="mini" style="width: 120px"
              @change="searchInit()">
            </el-input>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="查询" placement="top">
              <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
              <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="supplier" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow
      @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="name" min-width="120" title="供应商名称"></vxe-table-column>
      <vxe-table-column field="mobile" min-width="150" title="手机号"></vxe-table-column>
      <vxe-table-column field="address" min-width="150" title="地址"></vxe-table-column>
      <vxe-table-column field="person" min-width="150" title="负责人"></vxe-table-column>
      <vxe-table-column field="note" min-width="150" title="备注"></vxe-table-column>
      <vxe-table-column fixed="right" align="center" width="120" title="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-view" @click="getCheck(scope.row)">查看</el-button>
          <el-button type="text" size="mini" icon="el-icon-edit" @click="getUpdate(scope.row)" v-if="isClose == '0'">编辑
          </el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
      :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
      <template v-slot:left>
        <div class="fl footer-check">
          <p>
            已选
            <b style="font-size: 10px">{{ checkLength }}</b> 条
          </p>
        </div>
        <el-button type="primary" size="mini" icon="el-icon-turn-off" @click="getIsClose('close')"
          v-if="isClose == '0'">
          关闭</el-button>
        <el-button type="danger" size="mini" icon="el-icon-delete" @click="getDelete()" v-if="isClose == '0'">
          删除</el-button>
        <el-button type="success" size="mini" icon="el-icon-open" @click="getIsClose('open')" v-if="isClose == '1'">打开
        </el-button>
      </template>
    </vxe-pager>
    <check ref="check" />
    <detail ref="detail" @init="init" />
  </div>
</template>

<script>
import check from './components/check'
import detail from './components/detail'

export default {
  name: 'supplier',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 190 + 'px',
      allAlign: 'center',
      loading: false,
      checkBox: [],
      checkLength: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      isClose: '0',
      search: {},
      isNull: ['', null, undefined],
    }
  },
  created() {
    this.init()
  },
  components: { check, detail },
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.supplier) {
        this.tableHeight = window.innerHeight - this.$refs.supplier.$el.offsetTop - 62 + 'px'
      }
    }
  },
  methods: {
    init() {
      this.loading = true
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
          isClose: this.isClose,
        },
      }
      if (!this.isNull.includes(this.search.supplierName)) params.params.supplierName = this.search.supplierName
      if (!this.isNull.includes(this.search.mobile)) params.params.mobile = this.search.mobile
      this.$axios
        .get('/order/supplier/page', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            this.data = data.list
            this.page.currentPage = data.pageNum
            this.page.totalResult = data.total
            this.checkBox = []
            this.checkLength = 0
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.supplier.openExport()
    },
    printDataEvent() {
      this.$refs.supplier.openPrint()
    },
    getCheck(row) {
      this.$axios.get(`/order/supplier/detail/${row.id}`).then((res) => {
        if (res) {
          let data = {
            data: res.data.data,
          }
          this.$refs.check.display(data)
        }
      })
    },
    getdetail() {
      let data = {
        data: {},
        name: '新增供应商',
      }
      this.$refs.detail.display(data)
    },
    getUpdate(row) {
      let data = {
        data: row,
        name: `编辑${row.name}`,
      }
      this.$refs.detail.display(data)
    },
    /** 选择条数 */
    changeBoxChange() {
      this.checkBox = this.$refs.supplier.getCheckboxRecords()
      this.checkLength = this.$refs.supplier.getCheckboxRecords().length
    },
    /** 条数更改 */
    handlePageChange({ currentPage, pageSize }) {
      const _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    searchInit() {
      this.page.currentPage = 1
      this.init()
    },
    getIsClose(type) {
      let text = ''
      let isOk = false
      if (this.checkLength > 0) {
        isOk = true
      } else {
        isOk = false
        this.$message.warning('请选择至少一条数据')
      }
      if (isOk) {
        const ids = this.checkBox.map((item) => item.id)
        if (type === 'close') {
          text = '关闭'
        } else {
          text = '打开'
        }
        this.$confirm(`确定${text}这些数据吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$axios.post(`/order/supplier/${type}`, ids).then((res) => {
              if (res) {
                this.init()
                this.$message.success(`${text}成功`)
              }
            })
          })
          .catch(() => {})
      }
    },
    getDelete() {
      let isOk = false
      if (this.checkLength > 0) {
        isOk = true
      } else {
        isOk = false
        this.$message.warning('请选择至少一条数据')
      }
      if (isOk) {
        const ids = this.checkBox.map((item) => item.id)
        this.$confirm(`确定删除这些数据吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$axios.post('/order/supplier/delete', ids).then((res) => {
              if (res) {
                this.init()
                this.$message.success('删除成功')
              }
            })
          })
          .catch(() => {})
      }
    },
  },
}
</script>

<style scoped></style>
